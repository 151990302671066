import React, { Fragment } from 'react';
import { OrganizationConstants } from '../../adapters/helpers/Constants';
import Script from 'next/script';
import { setProfileData } from '../Janrain/helpers/localstorage.helpers';

const GTMScriptFunction = (props) => {
    if (typeof window !== 'undefined' && !window && !window.PGdataLayer && !window.PGdataLayer.user) {
        setProfileData();
    }
    const { languageCode, countryCode, productVariant = undefined, isDisableCookieConsent = false } = props;
    var PGdataLayer;
    return (
        <Fragment>
            <Script strategy='afterInteractive' id='gtm-script' type='text/javascript' dangerouslySetInnerHTML={{
                __html: `
                var interval=setInterval(()=>{
                if(document.readyState === 'complete'){
                    PGdataLayer = {
                        'page' : {
                            'url' : '',
                            'title' : '',
                          },

                          'GTM': {
                            'SiteCountry':'${countryCode}',
                              'GoogleAnalyticsSiteSpeedSampleRate':'regular',
                              'GoogleAnalyticsAllowLinker':'false',
                              'GoogleAnalyticsOptimizeContainerID':'${process.env.GA_OPTIMIZE_CONTAINER_ID}',
                              'GoogleReCaptcha':'false',
                              'GoogleAnalyticsReportingView':'${process.env.GA_REPORTING_VIEW}',
                              'GoogleAnalyticsConsentRequired':'true',
                              'GhosteryOverlay': '',
                              'GhosteryOverlayID': '',
                              'SiteHost': 'Azure Devops',
                              'SiteEnvironment': '${process.env.SITE_ENVIRONMENT}',
                              'SiteTechnicalAgency': 'Mindtree',
                              'SiteLocalContainer': '${process.env.SITE_LOCALCONTAINER}',
                              'GoogleAnalyticsLocal': '${process.env.GA_TRACKING_CODE}',
                              'ConsentOverlay': '${isDisableCookieConsent ? '' : process.env.CONSENT_OVERLAY}',
                              'ConsentOverlayID': '${isDisableCookieConsent ? '' : process.env.CONSENT_OVERLAY_ID}',
                              'SitePrivacyProtection': '${isDisableCookieConsent ? '' : process.env.SITE_PRIVACY_PROTECTION}',
                              'SiteTouchpoint': '${process.env.DL_TOUCHPOINT_ID}',
                              'SiteBrand':  'OralB',
                              'SiteLanguage':  '${languageCode}',
                              'SitePlatform': 'ModernWeb',
                              'SiteStatus': '${process.env.DL_SITE_STATUS}',
                              'FacebookRemarketingID': '${process.env.FACEBOOK_REMARKETING_ID}',
                              'Segment': '${process.env.PG_DATA_LAYER_GTM_SEGMENT}',
                              'Lytics': '${process.env.PG_DATA_LAYER_GTM_LYTICS}',
                              'GoogleAnalyticsGA4MeasurementID': '${process.env.GOOGLE_ANALYTICS_GA4_MEASUREMENT_ID}',
                            }
                        };
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.GTM_TRACKING_ID}');
                    clearInterval(interval)
                }},3500)
            `}} />
            {productVariant &&
                <Script strategy='afterInteractive' id='product-variant' type='text/javascript' dangerouslySetInnerHTML={{
                __html: `
                    var interval=setInterval(()=>{
                    if(document.readyState === 'complete'){
                        PGdataLayer.product =  {
                            'gtin': '${productVariant?.fields?.sku || ''}',
                            'name': '${productVariant?.fields?.name || ''}',
                            'brand': '${OrganizationConstants.brand || ''}',
                            'upc': '${productVariant?.fields?.sku || ''}'
                          };
                    clearInterval(interval)
                    }},3500)
                `}} />
            }
        </Fragment>
    )
}

export default GTMScriptFunction;